<template>
  <v-select
    :value="statuses"
    :items="items"
    :menu-props="{ bottom: true, offsetY: true }"
    label="Status"
    hide-details
    multiple
    clearable
    outlined
    class="case-status-select"
    @change="handleSelectChange"
  >
    <template v-slot:selection="{ item, index }">
      <div
        v-if="index === 0"
        class="d-flex flex-start align-center"
        style="width: 70%"
      >
        <v-icon left>
          {{ CasesStatusesIcons[item] }}
        </v-icon>
        <span class="text-truncate">{{ item }}</span>
      </div>
      <span
        v-if="index === 1"
        class="grey--text text-caption"
        style="width: 30%"
      >
        (+{{ statuses.length - 1 }} others)
      </span>
    </template>

    <template v-slot:item="{ item }">
      <div class="d-flex flex-start align-center">
        <v-icon left>
          {{ CasesStatusesIcons[item] }}
        </v-icon>
        <span class="d-block text-truncate">{{ item }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { CasesStatuses, CasesStatusesIcons } from "../../misc/constants";

export default {
  name: "CaseStatusSelect",

  props: {
    statuses: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => Object.values(CasesStatuses),
    },
  },

  data() {
    return {
      CasesStatusesIcons,
    };
  },

  methods: {
    handleSelectChange(value) {
      if (value && value.length === 0) {
        this.$emit("change", null);
        return;
      }
      this.$emit("change", value);
    },
    handleClear() {
      this.$emit("change", null);
    },
  },
};
</script>

<style lang="scss">
.case-status-select {
  .v-select__selections {
    width: 100%;
  }
}
</style>
