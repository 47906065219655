<template>
  <div>
    <v-chip v-if="status" outlined class="pa-0 pr-2 pl-1">
      <v-icon class="mr-1" color="grey darken-1">
        {{ CasesStatusesIcons[status] }}
      </v-icon>
      <span class="text-truncate">{{ status }}</span>
    </v-chip>
    <span v-else>-</span>
  </div>
</template>

<script>
import { CasesStatusesIcons } from "@/misc/constants";

export default {
  name: "CaseStatusChip",

  props: {
    status: {
      type: String || null,
      default: null,
    },
  },

  data() {
    return {
      CasesStatusesIcons,
    };
  },
};
</script>
