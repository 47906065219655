<template>
  <v-main class="clinician-audit">
    <div class="app-container pt-10 pb-3">
      <section
        class="clinician-audit__header d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="clinician-audit__text">
          <h1 class="text-h4 font-weight-bold">Clinical Documentation Audit</h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            Here you can view and filter cases.
          </p>
        </div>

        <div class="d-flex justify-start align-center">
          <a
            v-if="!isMobile"
            class="clinician-audit__header__filter-btn d-flex align-center justify-center"
            :class="{
              'clinician-audit__header__filter-btn--active': isFilterActive,
            }"
            @click="onFilterClick"
          >
            <v-icon color="black">mdi-filter-variant</v-icon>
          </a>
          <v-badge
            v-else
            :value="filterBadgeContent"
            :content="filterBadgeContent"
            offset-x="15"
            offset-y="20"
            color="primary"
          >
            <a
              class="clinician-audit__header__filter-btn d-flex align-center justify-center"
              @click="onFilterClick"
            >
              <v-icon color="black">mdi-filter-variant</v-icon>
            </a>
          </v-badge>

          <v-btn
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-2"
            @click="createBatchModal = true"
          >
            Create Audit
          </v-btn>
        </div>
      </section>

      <section class="clinician-audit__table">
        <v-skeleton-loader
          v-if="firstLoading"
          :loading="loading"
          type="table"
        />
        <v-data-table
          v-show="!firstLoading"
          :headers="headers"
          :items="fullCasesData"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalCases"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          :mobile-breakpoint="0"
          multi-sort
          @update:options="handleTableUpdate"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template
            v-if="isFilterActive && !isMobile && filtersOptions"
            v-slot:top
          >
            <v-row class="d-flex align-center mb-3">
              <v-col class="d-flex" cols="2">
                <SearchInput
                  :value="filters['case.caseReference']"
                  label="Search Case Id+"
                  @change="handleFilterChange($event, 'case.caseReference')"
                />
              </v-col>

              <v-col class="d-flex" cols="5">
                <CliniciansMultiselect
                  :value="filters.clinicianIds"
                  :selector="getClinicians"
                  :label="'Clinicians'"
                  @change="handleFilterChange($event, 'clinicianIds')"
                />
              </v-col>

              <v-col class="d-flex" cols="4">
                <CaseStatusMultiselect
                  :statuses="filters['audit.status']"
                  :items="caseStatuses"
                  @change="handleFilterChange($event, 'audit.status')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters['case.locationName']"
                  :items="filtersOptions.locationName"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Location"
                  hide-details
                  clearable
                  outlined
                  class="case-status-select"
                  @change="handleFilterChange($event, 'case.locationName')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters['case.source']"
                  :items="filtersOptions.source"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Source"
                  hide-details
                  clearable
                  outlined
                  class="case-status-select"
                  @change="handleFilterChange($event, 'case.source')"
                />
              </v-col>
              <v-col class="d-flex" cols="4">
                <ClinicalServicesMultiselect
                  :services="filters.clinicalService"
                  ref="servicesInput"
                  @change="handleFilterChange($event, 'clinicalService._id')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <TimeDataPicker
                  ref-name="menu1"
                  :date="filters['case.caseDate']"
                  label="Case Date"
                  @change="handleFilterChange($event, 'case.caseDate')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <TimeDataPicker
                  ref-name="menu2"
                  :date="filters['case.createdAt']"
                  label="Import Date"
                  @change="handleFilterChange($event, 'case.createdAt')"
                />
              </v-col>

              <v-col class="d-flex justify-center ml-12" cols="1">
                <CancelButton @click="onFilterCancel" />
              </v-col>
              <v-col class="d-flex justify-center ml-16" cols="1">
                <ApplyButton @click="fetchCases" />
              </v-col>
            </v-row>
          </template>

          <template v-slot:item="{ item }">
            <tr
              :class="
                getScoreRowColor(get(item, 'audit._id.caseGrade.score', null))
              "
              @click="goToCase(item)"
            >
              <td style="width: 100px">
                {{ get(item, "case.caseReference", "-") }}
              </td>
              <td style="width: 100px">
                {{ get(item, "case.source", "-") }}
              </td>
              <td style="min-width: 150px">
                {{ get(item, "clinicianName", "-") }}
              </td>
              <td style="min-width: 150px">
                {{ get(item, "clinicalService.name", "-") }}
              </td>
              <td style="max-width: 220px">
                <CaseStatusChip :status="get(item, 'audit.status', null)" />
              </td>
              <td style="min-width: 100px">
                {{ get(item, "audit.name", "-") }}
              </td>
              <td style="min-width: 120px">
                <span>{{ getAuditScore(item) }}</span>
              </td>
              <td>{{ get(item, "case.locationName", "-") }}</td>
              <td style="min-width: 140px">
                {{ minutesToDays(get(item, "duration", "-")) }}
              </td>
              <td style="min-width: 135px">
                <span>
                  {{ dateToLocalString(get(item, "case.caseDate"), false) }}
                </span>
              </td>
              <td style="min-width: 135px">
                <span>
                  {{ dateToLocalString(get(item, "case.createdAt"), false) }}
                </span>
              </td>
            </tr>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          :value="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>

      <v-dialog v-if="isMobile" v-model="isFilterActive" fullscreen persistent>
        <v-card flat class="clinician-audit__filter-menu">
          <div
            class="app-container pb-4 d-flex flex-column justify-space-between"
          >
            <div class="clinician-audit__filter-menu__filters pt-10">
              <h1 class="text-h4 font-weight-bold pb-5">Filters</h1>

              <SearchInput
                :value="filters['case.caseReference']"
                label="Search Case Id+"
                @change="handleFilterChange($event, 'case.caseReference')"
              />
              <br />
              <CliniciansMultiselect
                :value="filters.clinicians"
                :selector="getClinicians"
                :label="'Clinicians'"
                @change="handleFilterChange($event, 'clinicianIds')"
              />
              <br />
              <CaseStatusMultiselect
                :statuses="filters['audit.status']"
                :items="caseStatuses"
                @change="handleFilterChange($event, 'audit.status')"
              />
              <br />
              <v-select
                :value="filters['case.locationName']"
                :items="filtersOptions.locationName"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Location"
                hide-details
                clearable
                outlined
                class="case-status-select"
                @change="handleFilterChange($event, 'case.locationName')"
              />
              <br />
              <v-select
                :value="filters['case.source']"
                :items="filtersOptions.source"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Location"
                hide-details
                clearable
                outlined
                class="case-status-select"
                @change="handleFilterChange($event, 'case.source')"
              />
              <br />
              <ClinicalServicesMultiselect
                :services="filters.clinicalService"
                ref="servicesInput"
                @change="handleFilterChange($event, 'clinicalService._id')"
              />
              <br />
              <TimeDataPicker
                ref-name="menu1"
                :date="filters['case.caseDate']"
                label="Case Date"
                @change="handleFilterChange($event, 'case.caseDate')"
              />
              <br />
              <TimeDataPicker
                ref-name="menu2"
                :date="filters['case.createdAt']"
                label="Import Date"
                @change="handleFilterChange($event, 'case.createdAt')"
              />
            </div>

            <div
              class="clinician-audit__filter-menu__controls d-flex flex-column"
            >
              <ApplyButton large class="mb-5" @click="fetchCases" />
              <CancelButton large @click="onFilterCancel" />
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <CreateBatchModal
      :dialog="createBatchModal"
      :batch-type="BatchTypes.docReview"
      :clinical-services="clinicalServicesWithTemplates"
      @toggle="onBatchModalToggle"
    />
  </v-main>
</template>

<script>
import dateToLocalString from "@/helpers/dateToLocalString";
import { minutesToDays } from "@/helpers/getDateForDisplay";
import SearchInput from "@/components/controls/SearchInput.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import CaseStatusMultiselect from "@/components/controls/CaseStatusMultiselect.vue";
import CreateBatchModal from "@/components/batch/CreateBatchModal.vue";
import CaseStatusChip from "@/components/common/CaseStatusChip.vue";
import { getSelectOptions } from "@/services/cases";
import { getConclusions } from "@/services/conclusions";
import { get } from "@/helpers/get";
import { getScoreRowColor, getAuditScore } from "@/helpers/audit";
import {
  ClinicianStatuses,
  CasesStatusesIcons,
  TableRowsPerPage,
  CasesStatuses,
  BatchTypes,
} from "@/misc/constants";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";
import { getClinicalServices } from "@/services/clinicalServices";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";
import CliniciansMultiselect from "@/components/controls/CliniciansMultiselect.vue";
import { getClinicians } from "@/services/users";

export default {
  name: "ClinicianAuditPage",

  components: {
    CliniciansMultiselect,
    ClinicalServicesMultiselect,
    SearchInput,
    TimeDataPicker,
    CaseStatusMultiselect,
    CreateBatchModal,
    CaseStatusChip,
    ApplyButton,
    CancelButton,
  },

  data() {
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };
    const statuses = [
      CasesStatuses.compliant,
      CasesStatuses.partiallyCompliant,
      CasesStatuses.noncompliant,
      CasesStatuses.reviewNeeded,
    ];

    const headers = [
      {
        text: "Case Reference",
        align: "start",
        value: "case.caseReference",
        sortable: false,
      },
      {
        text: "Source",
        value: "case.source",
        sortable: false,
      },
      {
        text: "Clinician Name",
        value: "clinicianName",
        sortable: true,
      },
      {
        text: "Service",
        value: "clinicalService.name",
        sortable: false,
      },
      {
        text: "Status",
        value: "audit.status",
        sortable: false,
      },
      {
        text: "Audit",
        value: "batch.name",
        sortable: false,
      },
      {
        text: "Score, %",
        value: "audit.score",
        sortable: true,
      },
      {
        text: "Location",
        value: "locationName",
        sortable: false,
      },
      { text: "Duration", value: "duration" },
      { text: "Case Date", value: "case.caseDate" },
      {
        text: "Import date",
        value: "case.createdAt",
      },
    ];

    return {
      loading: true,
      firstLoading: true,
      options: {},
      totalCases: null,
      tableFooterOptions,
      filterStatuses: Object.values(ClinicianStatuses),
      isFilterActive: false,
      headers,
      caseStatuses: statuses,
      CasesStatusesIcons,
      fullCasesData: [],
      filters: {
        "case.caseReference": null,
        "audit.status": null,
        "case.caseDate": null,
        "case.createdAt": null,
        clinicianIds: null,
        "case.locationName": null,
        "case.source": null,
        "clinicalService._id": null,
        clinicalService: null
      },
      createBatchModal: false,
      filtersOptions: null,
      BatchTypes,
      allClinicalServices: null,
      clinicalServicesWithTemplates: []
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isFilterButtonActive() {
      return Object.values(this.filters).some((val) => !!val === true);
    },
    filterBadgeContent() {
      return Object.values(this.filters).reduce(
        (acc, value) => acc + !!value,
        0
      );
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalCases / this.options.itemsPerPage);
    },
  },

  async mounted() {
    this.filtersOptions = await getSelectOptions("locationName,source");

    if (this.$route.params.openModal) {
      this.createBatchModal = true;
    }
  },

  methods: {
    get,
    dateToLocalString,
    getScoreRowColor,
    minutesToDays,
    getAuditScore,
    getClinicians,
    async fetchCases() {
      const query = this.buildQuery();
      await this.fetchData(query);
      await this.fetchTotal(query);
    },

    async fetchData(query) {
      this.loading = true;
      try {
        if (!this.allClinicalServices) {
          await this.fetchClinicalServices();
        }

        const { data } = await getConclusions(query);
        this.fullCasesData = data.map((item) => ({
          ...item,
          clinicalService: this.getServiceById(
            get(item, "case.clinicalService")
          ),
        }));
        if (this.firstLoading) {
          this.totalCases = query?.pageSize;
        }
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },

    async fetchTotal(query) {
      try {
        const { total } = await getConclusions({ countTotal: true, ...query });
        this.totalCases = total;
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },

    async fetchClinicalServices() {
      try {
        this.allClinicalServices = await getClinicalServices();
        this.clinicalServicesWithTemplates = this.allClinicalServices.filter(service => service.templateId);
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Service fetch failed",
          text: error.response?.data?.message || error.message,
        });
      }
    },

    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;
      queryParams.batchType = BatchTypes.docReview;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }

      if (this.$route.query?.forCommunication) {
        queryParams.communication = true;
      }

      if (Object.values(this.filters).some((val) => !!val === true)) {
        const filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "case.caseDate" || key === "case.createdAt") {
              const [first, second] = this.filters[key];
              const startDate = new Date(first);
              const endDate = second
                ? new Date(second)
                : new Date(startDate.getTime() + 86400000);
              filters[key] = { $gte: startDate, $lt: endDate };
            } else if (key === "audit.status") {
              filters[key] = {
                $in: this.filters[key],
              };
            } else if (key === "case.caseReference") {
              filters[key] = { $regex: this.filters[key] };
            } else if (key === "clinicalService._id") {
              filters[key] = { $in: this.filters[key] };
            } else if (key === "clinicianIds") {
              filters[key] = { $in: this.filters[key] };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
        queryParams.filter = filters;
      }
      return queryParams;
    },
    async handleTableUpdate() {
      await this.fetchCases();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    onFilterClick() {
      this.isFilterActive = !this.isFilterActive;
    },
    goToCase(item) {
      const {
        case: { _id },
        type,
      } = item;
      const auditIdPath = item?.auditId instanceof Object ? "audit._id._id" : "audit._id";
      const auditId = get(item, auditIdPath, null);
      return auditId
        ? this.$router.push(`/cases/${_id}?caseType=${type}&auditId=${auditId}`)
        : this.$router.push(`/cases/${_id}?caseType=${type}`);
    },
    itemRowBackground(item) {
      return item.status === CasesStatuses.groupReview
        ? "group-review-needed-status"
        : "";
    },
    async handleFilterChange(value, key) {
      this.filters[key] = value;
      this.options.page = 1;
    },
    async onFilterCancel() {
      this.$refs.servicesInput.reset();
      Object.keys(this.filters).forEach((key) => (this.filters[key] = null));
      await this.fetchCases();
    },
    setDataForGroupReview() {
      this.status = CasesStatuses.groupReview;
      if (!this.isMobile) {
        this.isFilterActive = true;
      }
    },
    onBatchModalToggle(value) {
      this.createBatchModal = value;
    },
    getServiceById(id) {
      const foundService = this.allClinicalServices.find(
        (service) => id && service._id === id
      );
      return foundService;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.clinician-audit {
  .group-review-needed-status {
    background-color: #fff2f1;
  }

  .v-data-table__wrapper {
    overflow: unset;

    @media (max-width: 1200px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &__table {
    .v-data-table__wrapper {
      overflow-x: auto;
    }
  }

  &__header {
    &__filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }

  &__filter-menu {
    & .app-container {
      height: 100vh;
      padding-top: #{$headerHeight};
    }
  }
}
</style>
